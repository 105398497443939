<template>
  <div>
    <el-card class="box-card">
      <div class="header">
        <div class="version_img">
          <img src="../../../assets/college/Accounts/version_bgc.png" alt="" />
        </div>
        <div class="content">
          <div class="content_title">
            <div class="enterprise">企业版</div>
            <div class="introduce" @click="goUpgrade">版本介绍</div>
          </div>
          <div class="content_detail">
            <div class="content_wrap">
              <div class="content_detail_img">
                <img src="../../../assets/college/Accounts/h_l.png" alt="" />
              </div>
              <div class="content_detail_text">免平台服务费</div>
            </div>
            <div class="content_wrap right">
              <div class="content_detail_img">
                <img src="../../../assets/college/Accounts/h_r.png" alt="" />
              </div>
              <div class="content_detail_text">低至￥ 48.00/人/年</div>
            </div>
          </div>
        </div>
      </div>

      <div class="center">
        <el-row :gutter="26">
          <el-col :span="12">
            <div class="center_content">
              <div class="center_img">
                <img src="../../../assets/college/Accounts/content_l.png" alt="" />
              </div>
              <div class="center_wrap">
                <div class="center_wrap_title">{{ enterpriseLeft.chargeItemName }}</div>
                <div>
                  <span class="newPrice">￥{{ enterpriseLeft.chargePrice.toFixed(2) }}/年</span>
                  <span class="oldPrice">￥{{ enterpriseLeft.originalPrice.toFixed(2) }}</span>
                </div>
              </div>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="center_content">
              <div class="center_img">
                <img src="../../../assets/college/Accounts/content_r.png" alt="" />
              </div>
              <div class="center_wrap newcenter">
                <div class="center_wrap_title">
                  {{ enterpriseRight.chargeItemName }} <i class="el-icon-question"></i>
                </div>
                <div class="center_data">
                  <span class="newPrice">￥{{ enterpriseRight.chargePrice.toFixed(2) }}/人/年</span>
                </div>
              </div>
              <div class="center_footer">
                <div class="peopleWrap">
                  <div class="peopleWrapL">
                    <el-input
                      v-model="input"
                      clearable
                      oninput="value=value.replace(/[^\d]/g,'')"
                      placeholder="输入席位数"
                      @change="changeInput"
                    ></el-input>
                    <div class="people">人</div>
                    <div v-if="ruleStatus == true" class="rules">人数不能少于100人,大于10000人</div>
                  </div>
                  <div class="peopleWrapR">
                    <el-input-number
                      v-model="year"
                      :min="1"
                      :max="99"
                      @change="handleChange"
                    ></el-input-number>
                    <div class="people">年</div>
                  </div>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>

      <div class="footer">
        <div class="footer_wrap">
          <div class="footer_l">订单明细：</div>
          <div class="footer_r">
            <el-table
              :data="tableData"
              border
              :summary-method="getSummaries"
              show-summary
              style="width: 408px;"
            >
              <el-table-column prop="id" label="类目"> </el-table-column>
              <el-table-column prop="name" label="单价"> </el-table-column>
              <el-table-column prop="amount1" label="数量"> </el-table-column>
              <el-table-column prop="year" label="年"> </el-table-column>
              <el-table-column prop="amount2" label="总价"> </el-table-column>
            </el-table>
          </div>
        </div>
        <div class="footer_wrap">
          <div class="footer_l">订单详情：</div>
          <div class="footer_r">平台使用费：￥12999.00元；书院席位费：{{ price }}元</div>
        </div>
        <div class="footer_wrap">
          <div class="footer_l">有效期：</div>
          <div class="footer_r">{{ effectiveTime }}（按实际支付日期计算）</div>
        </div>
      </div>

      <div class="agreement">
        <!-- <div class="agreement_checked">
          <div><el-checkbox v-model="checked"></el-checkbox></div>
          <div class="agreement_text">勾选即同意《协议***********》</div>
        </div> -->
        <div class="btn">
          <el-button type="primary" size="medium" @click="sureBuy">確定购买</el-button>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import to from 'await-to'
import moment from 'moment'
import { getAccountsCharge, getUpdateOrder } from '@/api/college'
export default {
  data() {
    return {
      tableData: [
        {
          id: '平台使用费',
          name: '0',
          amount1: '1',
          amount2: '0',
          year: '1',
        },
        {
          id: '席位费',
          name: '48.00',
          amount1: '',
          amount2: '',
          year: '1',
        },
      ],
      ruleStatus: false,
      checked: false,
      input: '',
      year: 1,
      enterpriseLeft: {},
      enterpriseRight: {},
      price: 0,
      list: [],
      effectiveTime: '',
    }
  },
  watch: {
    input(newVal) {
      this.tableData.map((v, i) => {
        if (i == this.tableData.length - 1) {
          if (newVal !== '') {
            v.amount1 = newVal
            v.amount2 = (v.amount1 * v.name * this.year).toFixed(2)
            this.price = v.amount2
          } else {
            v.amount1 = ''
            v.amount2 = ''
            this.price = 0
          }
        }
      })
    },
    year(newVal) {
      this.tableData.map((v, i) => {
        v.year = newVal
        if (i == this.tableData.length - 1) {
          if (newVal !== '') {
            v.amount2 = (v.amount1 * v.name * newVal).toFixed(2)
            this.price = v.amount2
          }
        }
      })
    },
  },
  created() {
    this.getAccountsChargeData()
    this.effectiveTime = moment()
      .add(this.year, 'year')
      .format('YYYY-MM-DD')
  },
  methods: {
    async getAccountsChargeData() {
      const [res, err] = await to(getAccountsCharge())
      if (err) return this.$message({ type: 'error', message: err.msg })
      this.list = res.data
      this.enterpriseLeft = res.data[0]
      this.enterpriseRight = res.data[1]
    },
    sureBuy() {
      if (this.input == '') {
        this.$message({ type: 'warning', message: '请输入人数' })
      } else {
        this.changeInput()
        if (this.ruleStatus == false) {
          this.getUpdateOrderData()
        }
      }
    },
    async getUpdateOrderData() {
      let arr = []
      this.list.map(v => {
        let params = {
          chargeItemCode: v.chargeItemCode,
          chargePackageCode: v.chargePackageCode,
          num: this.input,
          time: this.year,
        }
        arr.push(params)
      })
      const [res, err] = await to(getUpdateOrder({ items: arr }))
      if (err) return this.$message({ type: 'error', message: err.msg })
      // this.$router.push({
      //   path: 'orderPay',
      //   query: { buyNum: this.input, orderNo: res.data, year: this.year },
      // })
      this.$router.push(`/college/orderPay/${this.input}/${res.data}/${this.year}`)
    },
    handleChange(val) {
      this.year = val
    },
    goUpgrade() {
      this.$router.push('/college/versionIntroduction')
    },
    changeInput() {
      if (Number(this.input) < 100 || Number(this.input > 10000)) {
        this.ruleStatus = true
        this.input = ''
      } else {
        this.ruleStatus = false
      }
    },
    getSummaries(param) {
      const { columns, data } = param
      const sums = []
      //   console.log(columns, data)
      var price = 0
      data.map(v => {
        price += Number(v.amount2)
      })
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '小计'
          return
        }
        if (index === columns.length - 1) {
          sums[index] = price.toFixed(2) + '元'
          return
        }
      })

      return sums
    },
  },
}
</script>

<style scoped lang="scss">
.box-card {
  padding-bottom: 100px;
  .header {
    position: relative;
    .version_img {
      height: 162px;
    }
    .content {
      position: absolute;
      left: 50%;
      top: 0;
      transform: translateX(-50%);
      .content_title {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 23px 0;
        .enterprise {
          font-weight: 700;
          font-size: 36px;
          color: #ae9568;
        }
        .introduce {
          color: #99835b;
          font-size: 14px;
          text-decoration: underline;
          margin-left: 15px;
          cursor: pointer;
        }
      }
      .content_detail {
        display: flex;
        .content_wrap {
          position: relative;
          .content_detail_img {
            width: 220px;
            height: 62px;
          }
          .content_detail_text {
            position: absolute;
            left: 73px;
            top: 50%;
            transform: translateY(-50%);
            font-size: 18px;
            color: #ae9568;
          }
        }
      }
      .right {
        margin-left: 50px;
      }
    }
  }
  .center {
    margin-top: 26px;
    height: 136px;
    .center_content {
      position: relative;
      .center_img {
        height: 136px;
      }
      .center_wrap {
        position: absolute;
        left: 0px;
        top: 30px;
        .center_wrap_title {
          font-size: 16px;
          color: #606266;
          margin-bottom: 18px;
          padding-left: 29px;
          i {
            font-size: 16px;
          }
        }
        .newPrice {
          padding-left: 29px;
          box-sizing: border-box;
          font-size: 24px;
          color: #ff7b33;
        }
        .oldPrice {
          font-size: 14px;
          color: #606266;
          margin-left: 8px;
          text-decoration: line-through;
        }
      }
      .newcenter {
        display: flex;
        justify-content: space-between;
        width: 100%;
      }
      .center_data {
        padding-right: 60px;
      }
    }
    .center_footer {
      display: flex;
      width: 100%;
      position: absolute;
      left: 0px;
      bottom: 20px;
      .peopleWrap {
        display: flex;
        justify-content: space-between;
        padding: 0 30px;
        width: 100%;
        font-size: 16px;
        color: #333;
        .peopleWrapL {
          display: flex;
          align-items: center;
          position: relative;
          ::v-deep .el-input .el-input__inner {
            width: 104px;
          }
          .rules {
            position: absolute;
            font-size: 12px;
            left: 0;
            bottom: -18px;
            color: #f56c6c;
            width: 200px;
          }
        }
        .peopleWrapR {
          display: flex;
          align-items: center;
          font-size: 16px;
          color: #333;
        }
        .people {
          margin-left: 10px;
        }
      }
    }
  }
  .footer {
    margin-top: 32px;
    border: 1px solid #c2c2c2;
    padding: 32px 0 30px 31px;
    color: #606266;
    font-size: 14px;
    .footer_wrap {
      display: flex;
      &:nth-child(2) {
        margin: 20px 0;
      }
    }
  }
  .agreement {
    margin-top: 66px;
    .agreement_checked {
      display: flex;
      line-height: 20px;
      justify-content: center;
      .agreement_text {
        color: #909399;
        font-size: 14px;
        margin-left: 5px;
        cursor: pointer;
      }
    }
    .btn {
      text-align: center;
      margin-top: 17px;
    }
  }
  img {
    width: 100%;
    height: 100%;
  }
}
</style>
